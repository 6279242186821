import React from 'react'
import { Container } from 'react-bootstrap'
import { Box, Title, Text, Section } from '../Core'
import styled from 'styled-components'
import { Row, Col } from 'react-bootstrap'
import { device } from '../../utils'
import { Link } from "gatsby"

import Logo from '../Logo'

import earLogoDark from '../../assets/ear/black_ear_logo.svg'

import facebookLogo from '../../assets/ear/socials/facebook.svg'
import instagramLogo from '../../assets/ear/socials/instagram.svg'
import linkedInLogo from '../../assets/ear/socials/linkedin.svg'

import award from '../../assets/ear/award.png'

import i1 from '../../assets/ear/insta/i_1.png'
import i2 from '../../assets/ear/insta/i_2.png'
import i3 from '../../assets/ear/insta/i_3.png'
import i4 from '../../assets/ear/insta/i_4.png'

import { Helmet } from 'react-helmet'


const StyledBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  position: relative;

  .block {
    width: 75vw;
  }

  .award {
    padding-top: 20px;
    width: 80px;
    float: left;
    display: none !important;
  }

  p {
    color: black;
    text-align: left;
  }

  .heading {
    font-size: 25px;
    line-height: 35px;

    font-weight: 200;
    padding-bottom: 2rem;
  }

  .footer-block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-left: 1px solid black;
    padding-bottom: 2rem;
    padding-top: 1rem;

    padding-left: 25px;
  }

  .mobile-award .award {
    display: none;
  }

  .disclaimer {
    padding-top: 10px;
    font-size: 15px;
    line-height: 25px;
    max-width: 95%;
    font-weight: 200;
    float: left;
  }

  .sign-up {
    padding-top: 10px;
    font-size: 20px;
    line-height: 35px;
    font-weight: 200;
  }

  .t-bold {
    text-transform: uppercase;
  }

  .link {
    font-weight: 200;
    font-size: 20px;
    line-height: 40px;
    letter-spacing: 0.5px;
  }

  .socials img {
    padding-right: 32px;
  }

  .developed-by {
    padding-top: 2rem;
    font-size: 20px;
    font-weight: 200;
    text-align: center;
  }

  .developed-by a{
    color: black !important;  
  }

  .socials-mobile {
    display: none;
  }

  .socials-mobile img {
    padding-right: 30px;
  }

  .nb {
    border-left: none;
  }


  .link a{
    color: black !important;
  }
  @media ${device.sm} {
    padding-top: 2rem;
    padding-bottom: 2rem;
    .email {
      display: none;
    }

    .socials-mobile {
      padding-bottom: 10px;
      display: flex;
    }

    .heading {
      font-size: 18px;
      padding-bottom: 0.5rem;
    }

    .link {
      font-size: 15px;
      line-height: 30px;
    }

    .follow-block {
      display: none;
    }

    .footer-block {
      border-left: none;
      padding-bottom: 1rem;
    }

    .contact-us {
      display: none;
    }

    .developed-by {
      padding-top: 0px;
      text-align: left;
      font-size: 15px;
      padding-left: 25px;
    }
    .mobile-award .award {
      display: block;
    }
  }

  @media ${device.md} {
    overflow-wrap: anywhere;

    .mobile-award .award {
      display: none;
    }

    padding-top: 2rem;
    padding-bottom: 2rem;

    .footer-container {
      width: 80vw;
      margin: 2rem;
      max-width: 1300px;
    }
    .email {
      display: none;
    }

    .nb-t {
      border-left: none;
    }

    .socials-mobile {
      display: none;
    }

    .link {
      font-size: 15px;
      line-height: 30px;
    }

    .developed-by {
      padding-top: 20px;
      text-align: center;
      font-size: 15px;
      padding-left: 25px;
    }

    .follow-block .socials img {
      height: 20px;
    }
  }

  @media ${device.lg} {

    .pages{
      white-space: nowrap;
    }

    .email {
      display: block;
    }

    .nb-t {
      border-left: 1px solid black;
    }

    .follow-block {
      
      display: flex;
    }
  }

  .email-fix {
    padding-bottom: 10px;
  }
`

const StyledInput = styled.input`
  background: #f1f3f4;
  padding-top: 10px;
  width: 95%;
  font-size: 15px;
  margin-bottom: 5px;
  border: unset;
  border-bottom: 1px solid black;
  float: left;
`

const InstaBox = styled.div`
  padding-top: 2rem;
  padding-bottom: 2rem;
  text-align: center !important;


  .powr-instagram-feed{
      padding-top: 1rem;
  }

  position: relative;
  overflow-y: hidden;
  background-color: black;

  .mobile {
    display: none;
  }

  .desktop {
    display: block;
  }

  .title {
    font-family: 'Vijaya';
    font-size: 50px;
    line-height: 45px;
  }

  .copy {
    padding-top: 25px;
    text-transform: uppercase;
    font-weight: 200;
    font-size: 25px;
    line-height: 30px;
  }

  .account {
    padding-top: 15px;
    text-transform: uppercase;
    font-size: 25px;
    line-height: 30px;
  }

  .insta-info {
    padding-top: 4rem;
    padding-bottom: 4rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  @media ${device.sm} {

    .insta-info {
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
    .mobile {
      display: block;
    }

    .desktop {
      display: none;
    }

    .title {
      font-size: 30px;
      line-height: 30px;
    }

    .copy {
      font-size: 15px;
      line-height: 25px;
    }

    .account {
      font-size: 15px;
      line-height: 25px;
    }
  }

  .insta-images {
    display: flex;
    flex-direction: row;
  }
`

const Footer = ({ isDark = true }) => {
  return (
    <>
      {/* <!-- Footer section --> */}
      <StyledBox
        bg="#F1F3F4"
        pt={[0, null, null, '3.75rem']}
        pb="3.75rem"
        className="position-relative"
      >
        <Container>
          <Row>
            <Col xs={8} md={5} xl={3} className="footer-block nb">
              <img src={earLogoDark} className="w-75"></img>
            </Col>
          </Row>

          <Row>
            <Col md={6} lg={3} className="footer-block email nb">
              <Text className="heading email-fix">
                Subscribe to our newsletter
              </Text>
              <form name="contact" method="POST" netlify netlify-honeypot="bot-field" action="/thankyou/"   data-netlify="true">
              <input type="hidden" name="form-name" value="newsletter" />
              <StyledInput name="email"  type="email" placeholder="Email" />
              <button type="submit" css={{all: "unset", float: "left"}}><Text className="sign-up" css={{paddingTop: "0px"}}>SIGN UP</Text></button>
              </form>
              <Text className="disclaimer">
                By clicking SIGN UP you agree to receive emails from East Avenue
                Realty and to our term of use and privacy policy.
              </Text>
            </Col>
            <Col md={3} lg={3} className="footer-block nb-t">
              <Text className="heading t-bold">Pages</Text>
              <div>
                <Row className='pages'>
                  <Col lg={6}>
                    <Link to="/"><Text className="link">Home</Text></Link>
                    <Link to="/about"><Text className="link">About</Text></Link>
                    <Link to="/rentals"><Text className="link">Properties</Text></Link>
                    <Link to="/services"><Text className="link">Services</Text></Link>
                  </Col>
                  <Col lg={6}>
                  <Link to="/blog"><Text className="link">Blog</Text></Link>
                  <Link to="/contact"><Text className="link">Contact</Text></Link>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={5} lg={3} className="footer-block mobile-award">
              <Text className="heading t-bold">Keep In Touch</Text>
              <div className="socials socials-mobile">
                <a target="_blank" href="https://www.instagram.com/eastavenuerealty/"><img height="20px" src={instagramLogo} /></a>
                <a target="_blank" href="https://www.facebook.com/eastavenuerealty/"><img height="20px" src={facebookLogo} /></a>
                <a target="_blank" href="https://www.linkedin.com/in/lina-davies-999ab68a/"><img height="20px" src={linkedInLogo} /></a>
              </div>

              <div>
                <Link to="/contact"><Text className="link contact-us">Contact Us</Text></Link>
                <Text className="link">
                  <br />
                  PO Box 1519, Maroubra NSW 2035
                  <br />
                  <a href="tel:0402 268 096">0402 268 096</a>
                  <br />
                  <a href="mailto:lina@eastavenuerealty.com.au">lina@eastavenuerealty.com.au</a>
                </Text>
                <img className="award" src={award} />
              </div>
            </Col>
            <Col md={4} lg={3} className="footer-block follow-block">
              <Text className="heading t-bold">Follow us</Text>
              <div className="socials">
              <a target="_blank" href="https://www.instagram.com/eastavenuerealty/"><img height="32px" src={instagramLogo} /></a>
                <a target="_blank" href="https://www.facebook.com/eastavenuerealty/"><img height="32px" src={facebookLogo} /></a>
                <a target="_blank" href="https://www.linkedin.com/in/lina-davies-999ab68a/"><img height="32px" src={linkedInLogo} /></a>

              </div>
              <img className="award" src={award} />
            </Col>
          </Row>
          <Row>
            <Text className="developed-by"> Developed By <a href="https://tensq.com.au" target="_blank">Ten Squared</a></Text>
          </Row>
        </Container>
      </StyledBox>
    </>
  )
}

export default Footer
